import { isEvalaProduction } from "../global.utils";
import { logger } from "./log";

abstract class Analytics {
    readonly ID: string;

    async initialize(userId: string): Promise<void> {
        throw new Error("Method 'initialize' must be implemented.");
    }

    async sendEvent(event: string): Promise<void> {
        throw new Error("Method 'sendEvent' must be implemented.");
    }

    enableTracking(): void {
        // @ts-ignore
        // window["ga-disable-" + this.ID] = false;
    }

    /**
     * Maybe disables tracking
     * https://stackoverflow.com/a/26758245/3352544
     * */
    disableTracking(): void {
        // @ts-ignore
        // window["ga-disable-" + this.ID] = true;
    }
}


class ReactGA4Wrapper extends Analytics {
    readonly ID = "G-JDMH610TNZ";
    ReactGA4: typeof import("react-ga4");

    async initialize(userId: string): Promise<void> {
        // log registered user into Google Analytics,
        // lazy load the library so that it doesn't pollute app pointlessly
        if (!this.ReactGA4) {
            this.ReactGA4 = await import("react-ga4");
            this.ReactGA4.default.initialize(this.ID, {
                gaOptions: {
                    userId: userId
                }
            });
            this.disableTracking();
        }
    }

    async sendEvent(event: string): Promise<void> {
        /** We only want to send one custom event to GA/GTM,
         * but once the script is loaded, it fires event everytime something happens on the page.
         * => try to disable it after we fire our custom event to prevent it from firing more of them.*/
        this.enableTracking();
        this.ReactGA4.default.event({
            category: event,
            action: event
        });
        this.disableTracking();
    }
}

class ReactGTAGWrapper extends Analytics {
    readonly ID = "GTM-5TF563J";
    TagManager: typeof import("react-gtm-module");

    async initialize(): Promise<void> {
        if (!this.TagManager) {
            this.TagManager = await import("react-gtm-module");
            this.TagManager.default.initialize({
                gtmId: this.ID
            });
            this.disableTracking();
        }
    }

    async sendEvent(event: string): Promise<void> {
        this.enableTracking();
        this.TagManager.default.dataLayer({
            dataLayer: {
                event: event
            }
        });
        this.disableTracking();
    }
}

const ReactGA4WrapperInstance = new ReactGA4Wrapper();
const ReactGTAGWrapperInstance = new ReactGTAGWrapper();

export const fireGAEvent = async (event: string, userId: string): Promise<void> => {
    if (isEvalaProduction()) {
        // try catch, just to be safe
        try {
            await ReactGA4WrapperInstance.initialize(userId);
            ReactGA4WrapperInstance.sendEvent(event);
        } catch (e) {
            logger.warn("google analytics EVENT call failed", e);
        }
    }
};

export const fireGATag = async (event: string): Promise<void> => {
    if (isEvalaProduction()) {
        try {
            await ReactGTAGWrapperInstance.initialize();
            ReactGTAGWrapperInstance.sendEvent(event);
        } catch (e) {
            logger.warn("google analytics TAG call failed", e);
        }
    }
};